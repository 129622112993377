import axiosClient from 'utils/axiosClient';
import api from 'api';
import _ from 'lodash';

/**
 * Get
 */

const getFlights = async (data?: any) => {
	let newData = {} as any;
	if (data) {
		newData = {startDate: data.startDate, endDate: data.endDate};
	}
	if (_.has(data, 'classes') && !isNaN(data.classes)) {
		newData.classes = data.classes;
	}
	const response = await axiosClient.post(api.PRIVATE.GET_FLIGHTS, newData);
	return response.data;
};
const getFlightById = async (id: string) => {
	const response = await axiosClient.get(api.PRIVATE.GET_FLIGHTS + id);
	return response.data;
};

const getFlightClasses = async (id: string) => {
	const response = await axiosClient.get(api.PRIVATE.GET_FLIGHT_CLASSES + id);
	return response.data;
};

/**
 * Create
 */

const createFlight = async (data: any) => {
	const response = await axiosClient.post(api.PRIVATE.CREATE_FLIGHT, data);
	return response.data;
};

const createFlightClass = async (data: any, id: string) => {
	const response = await axiosClient.post(api.PRIVATE.CREATE_FLIGHT_CLASS + id, data);
	return response.data;
};

/**
 * Update
 */

const updateFlight = async (data: any, id: string) => {
	const response = await axiosClient.post(api.PRIVATE.UPDATE_FLIGHT + id, data);
	return response.data;
};

const updateFlightClass = async (data: any, id: string) => {
	const response = await axiosClient.post(api.PRIVATE.UPDATE_FLIGHT_CLASS + id, data);
	return response.data;
};

/**
 * Delete
 */

const deleteFlight = async (id: string) => {
	const response = await axiosClient.get(api.PRIVATE.DELETE_FLIGHT + id);
	return response.data;
};

const deleteFlightClass = async (id: string) => {
	const response = await axiosClient.get(api.PRIVATE.DELETE_FLIGHT_CLASS + id);
	return response.data;
};

const removePassenger = async (flightId: string, passengerId: string) => {
	const response = await axiosClient.post(api.PRIVATE.REMOVE_PASSENGER + `${flightId}/${passengerId}`);
	return response.data;
};

const service = {
	getFlights,
	getFlightById,
	removePassenger,
	getFlightClasses,
	createFlight,
	createFlightClass,
	updateFlight,
	updateFlightClass,
	deleteFlight,
	deleteFlightClass
};

export default service;
