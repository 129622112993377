// API with token

/**
 * Search
 */
const SEARCH_FLIGHT = '/api/search/mainSearch';
const PRE_ORDER = '/api/orders/pre-order';
const CREATE_ORDER = '/api/orders/order';
const ORDER_DATE = '/api/orders/orderDate/';

/**
 * Reservations
 */
const GET_ORDERS = '/api/orders/get-orders';
const GET_ORDER_BY_PNR = '/api/orders/get-order-by-pnr/';
const UPDATE_ORDER = '/api/orders/edit/';
const CANCEL_PRE_ORDER = '/api/orders/cancel-pre-order/';
const CANCEL_BOOKING = '/api/orders/cancel/';
const BOOKING_TO_TICKETING = '/api/orders/booking-to-ticketing/';
const REFUND_TICKET = '/api/orders/refund/';
const SPLIT_TICKET = '/api/orders/split/';
const CHANGE_PRICE = '/api/orders/editDiscount/';
const UPDATE_ORDER_PASSENGER_NAME = '/api/orders/editPassengerName/';

/**
 * Profile
 */
const PROFILE_API = '/api/profile';

/**
 * Reports
 */

const FLIGHT_REPORTS = '/api/reports/orders-full-data';

/**
 * Agents
 */
const GET_AGENTS = '/api/users/get-users/';
const GET_AGENTS_BY_ID = '/api/users/get-user/';
const GET_BALANCE_HISTORY = '/api/users/balance-history/';
const UPDATE_AGENT = '/api/users/update/';
const ADD_BALANCE = '/api/users/balance/transfer/';
const CHANGE_PASSWORD = '/api/users/change-password/';
const USE_BALANCE = '/api/users/use-balance/';

/**
 * Flights
 */
const GET_FLIGHTS = '/api/flights/get/';
const GET_FLIGHT_CLASSES = '/api/classes/get-by-flightId/';
const CREATE_FLIGHT = '/api/flights/create';
const CREATE_FLIGHT_CLASS = '/api/classes/create/';
const REMOVE_PASSENGER = '/api/flights/remove-passenger/';
const UPDATE_FLIGHT = '/api/flights/edit/';
const UPDATE_FLIGHT_CLASS = '/api/classes/edit/';
const DELETE_FLIGHT = '/api/flights/delete/';
const DELETE_FLIGHT_CLASS = '/api/classes/delete/';

/**
 * Services
 */
const GET_HISTORY = '/api/logs/get';

/**
 * History
 */
const HOTELS = '/api/hotels';
const GET_HOTEL_CATEGORY = '/api/hotels/category';

/**
 * History
 */
const SERVICES = '/api/services';

/**
 * Settings
 */
const GET_AIRPORTS = '/api/airport';
const GET_AIRLINES = '/api/airline';
const GET_AIRCRAFTS = '/api/aircraft';
const GET_FLIGHT_TEMPLATES = '/api/flights/templates';
const GET_FLIGHT_CLASS_TEMPLATES = '/api/classes/templates/';
const GET_FARE_RULES = '/api/fareRules';
const GET_BAGGAGE_RULES = '/api/baggageRules';
const GET_COMPANY_SETTINGS = '/api/companySettings/';
const GET_SMTP_SETTINGS = '/api/SMTPsettings';
const GET_TICKET_SETTINGS = '/api/ticketSettings';
const GET_NOTIFICATIONS = '/api/notifications/';
const GET_TIME_LIMITS = '/api/timeLimits/';
const CURRENCY = '/api/currencySettings/';
const CURRENCY_MARGIN_TYPES = '/api/currencySettings/MarginTypes';

const CREATE_AIRPORTS = '/api/airport';
const CREATE_AIRLINES = '/api/airline';
const CREATE_AIRCRAFT = '/api/aircraft';
const CREATE_FLIGHT_TEMPLATES = '/api/flights/templates';
const CREATE_FLIGHT_CLASS_TEMPLATES = '/api/classes/templates/';
const CREATE_TIME_LIMITS = '/api/timeLimits';
const CREATE_FARE_RULES = '/api/fareRules/';
const CREATE_BAGGAGE_RULES = '/api/baggageRules/';
const CREATE_COMPANY_SETTINGS = '/api/companySettings/';
const CREATE_SMTP_SETTINGS = '/api/SMTPsettings/';
const CREATE_TICKET_SETTINGS = '/api/ticketSettings/';
const CREATE_NOTIFICATIONS = '/api/notifications/';

const UPDATE_AIRPORT = '/api/airport/';
const UPDATE_AIRLINE = '/api/airline/';
const UPDATE_AIRCRAFT = '/api/aircraft/';
const UPDATE_FLIGHT_TEMPLATE = '/api/flights/templates/';
const UPDATE_FLIGHT_CLASS_TEMPLATES = '/api/classes/templates/';
const UPDATE_COMPANY_SETTINGS = '/api/companySettings/edit';
const UPDATE_TIME_LIMITS = '/api/timeLimits/';
const UPDATE_FARE_RULES = '/api/fareRules/';
const UPDATE_BAGGAGE_RULES = '/api/baggageRules/';
const UPDATE_SMTP_SETTINGS = '/api/SMTPsettings/';
const UPDATE_TICKET_SETTINGS = '/api/ticketSettings/';
const UPDATE_NOTIFICATIONS = '/api/notifications/';

const DELETE_AIRPORT = '/api/airport/';
const DELETE_AIRLINE = '/api/airline/';
const DELETE_AIRCRAFT = '/api/aircraft/';
const DELETE_FLIGHT_TEMPLATE = '/api/flights/templates/';
const DELETE_FLIGHT_CLASS_TEMPLATES = '/api/classes/templates/';
const DELETE_FARE_RULES = '/api/fareRules/';
const DELETE_BAGGAGE_RULES = '/api/baggageRules/';
const DELETE_NOTIFICATIONS = '/api/notifications/';

const PRIVATE_API = {
	SEARCH_FLIGHT,
	HOTELS,
	SERVICES,
	PRE_ORDER,
	BOOKING_TO_TICKETING,
	REFUND_TICKET,
	SPLIT_TICKET,
	PROFILE_API,
	FLIGHT_REPORTS,
	ADD_BALANCE,
	USE_BALANCE,
	CANCEL_PRE_ORDER,
	CANCEL_BOOKING,
	CHANGE_PRICE,
	CHANGE_PASSWORD,
	CURRENCY,
	CURRENCY_MARGIN_TYPES,
	REMOVE_PASSENGER,
	ORDER_DATE,
	GET_ORDERS,
	GET_ORDER_BY_PNR,
	GET_HISTORY,
	GET_HOTEL_CATEGORY,
	GET_AIRPORTS,
	GET_AIRLINES,
	GET_AIRCRAFTS,
	GET_FLIGHT_TEMPLATES,
	GET_FLIGHT_CLASS_TEMPLATES,
	GET_BAGGAGE_RULES,
	GET_COMPANY_SETTINGS,
	GET_FARE_RULES,
	GET_SMTP_SETTINGS,
	GET_TICKET_SETTINGS,
	GET_NOTIFICATIONS,
	GET_TIME_LIMITS,
	GET_FLIGHTS,
	GET_FLIGHT_CLASSES,
	GET_AGENTS,
	GET_AGENTS_BY_ID,
	GET_BALANCE_HISTORY,

	CREATE_FLIGHT,
	CREATE_FLIGHT_CLASS,
	CREATE_ORDER,
	CREATE_AIRPORTS,
	CREATE_AIRLINES,
	CREATE_AIRCRAFT,
	CREATE_FLIGHT_TEMPLATES,
	CREATE_FLIGHT_CLASS_TEMPLATES,
	CREATE_BAGGAGE_RULES,
	CREATE_COMPANY_SETTINGS,
	CREATE_FARE_RULES,
	CREATE_SMTP_SETTINGS,
	CREATE_TICKET_SETTINGS,
	CREATE_NOTIFICATIONS,
	CREATE_TIME_LIMITS,

	UPDATE_AGENT,
	UPDATE_ORDER,
	UPDATE_ORDER_PASSENGER_NAME,
	UPDATE_TIME_LIMITS,
	UPDATE_COMPANY_SETTINGS,
	UPDATE_FLIGHT,
	UPDATE_FLIGHT_CLASS,
	UPDATE_FLIGHT_CLASS_TEMPLATES,
	UPDATE_FLIGHT_TEMPLATE,
	UPDATE_AIRPORT,
	UPDATE_NOTIFICATIONS,
	UPDATE_TICKET_SETTINGS,
	UPDATE_SMTP_SETTINGS,
	UPDATE_AIRCRAFT,
	UPDATE_AIRLINE,
	UPDATE_BAGGAGE_RULES,
	UPDATE_FARE_RULES,

	DELETE_FARE_RULES,
	DELETE_BAGGAGE_RULES,
	DELETE_AIRPORT,
	DELETE_AIRLINE,
	DELETE_AIRCRAFT,
	DELETE_FLIGHT,
	DELETE_FLIGHT_CLASS,
	DELETE_FLIGHT_TEMPLATE,
	DELETE_FLIGHT_CLASS_TEMPLATES,
	DELETE_NOTIFICATIONS
};

export default PRIVATE_API;
